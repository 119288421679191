import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import IMG1 from '../../assets/images/posteffects4.png'

import './home.scss'


const Home = () => {
  return (
    <div>
      <Navbar   />
      <div className="full__image__container">
        
            <img src={IMG1} alt="forrest" />
        
   
      </div>

      <Footer />
    </div>
  )
}

export default Home
