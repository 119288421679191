import React, { useRef } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

import emailjs from '@emailjs/browser'

import './contact.scss'
import IMAGE from '../../assets/images/underground.png'
const Contact = () => {
  const form = useRef();
  const sendEmail =(e) =>{
    e.preventDefault();
    emailjs.sendForm('service_f0amg3d', 'template_wb6lzwh', form.current, 'pUZZ0i0RHMENnTJmq')
    alert('Your message has been sent. I will get back to you as soon as I can.')
    e.target.reset()
  }



  return (
    <div>
      <Navbar />
      <div className="contact__container">
        <div className="contact__form">
        <form ref={form} onSubmit={sendEmail} >
          <h3>Send me a message</h3>
          <label htmlFor="name">Name:</label>
          <input type="text" name="from_name" required />
          <label htmlFor="email">Email:</label>
          <input type="email" name="from_email" required />
          <label htmlFor="message">Message:</label>
          <textarea name="message" id="message" rows="10"  required></textarea>
          <div className="button__area">
             <button type="submit" className='btn__primary'>Send</button>
          </div>
         
        </form>
        </div>
        <div className="contact__image-block">
          <div className="image">
            <img src={IMAGE} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
