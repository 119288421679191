import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import './gallery.scss'
import GalleryGrid from '../../Components/Gallery/GalleryGrid'




function Gallery() {

  
  return (
    <div>
      <Navbar />
      <div className="gallery__container">

        
      
        <GalleryGrid className="gallery__image"/>
          
          
        
  
       
       
      </div>
      
      <Footer />
    </div>
  )
}

export default Gallery
