import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Divide as Hamburger } from "hamburger-react";
import "./navBar.scss";
import Fade from "react-awesome-reveal";

import { FaInstagram, FaArtstation, FaDeviantart } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, handleShow] = useState(false)
  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };

  const transitionNavBar = ()=>{
    if(window.scrollY > 50){
      handleShow(true);
    }else{
      handleShow(false)
    }
  }
  useEffect(() =>{
    window.addEventListener('scroll', transitionNavBar)
    return() => window.removeEventListener("scroll", transitionNavBar)
  }, [])


  return (
    <>
      <nav className={`top__nav ${show && 'nav__white'}`}>
        <Link to={"/"} className="logo">
          <h1>Chris Dickson </h1>
          
          <small>Art & Design</small>
        </Link>
        <div className="full__screen-menu">
          <ul>
          <li>
              <Link to={"/about"} className="link__to">
                About
              </Link>
            </li>
            <li>
              <Link to={"/gallery"} className="link__to">
                Gallery
              </Link>
            </li>
            <li>
              <Link to={"/contact"} className="link__to">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="burger__menu">
          <button onClick={toggleMenu}>
            <Hamburger />{" "}
          </button>
        </div>
      </nav>

      <div className={`mobile__overlay ${isOpen ? "is-open" : ""}`}>
        <ul>
          <Fade direction="up" cascade damping={0.3}>
            <li>
              <Link to={"/about"} className="link__to">
                About
              </Link>
            </li>
            <li>
              <Link to={"/gallery"} className="link__to">
                Gallery
              </Link>
            </li>
            <li>
              <Link to={"/contact"} className="link__to">
                Contact
              </Link>
            </li>
          </Fade>
        </ul>
        <div className="social__icons">
          <Fade cascade damping={0.5} direction="up">
            <a href="https://www.instagram.com/cdickson231/">
              <FaInstagram className="icon" />
            </a>
            <a href="https://www.artstation.com/chrisdickson7">
              <FaArtstation className="icon" />
            </a>
            <a href="https://www.deviantart.com/eggman231">
              <FaDeviantart className="icon" />
            </a>
          </Fade>
        </div>
      </div>

    </>
  );
};

export default Navbar;
