import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import './about.scss'
import Image1 from '../../assets/images/underground.png'
import {Link} from 'react-router-dom'
const About = () => {
  return (
    <div>
      <Navbar />
      <div className="about__block">
        <div className="about__img">
          <img src={Image1} alt="underground  rendered in blender" />
        </div>
        <div className="about__me-text">
          <h1>All about me</h1>
          <p>Hi thanks for visiting my portfolio, I’m Chris a mixed media artist. I have decided to redo this portfolio page using the skills and tools I have learned from my 4 years of college and university. I work with a mixture of tools from photoshop to 3ds max to create art. Over the course of my life, I have always had a deep love for art and mostly worked with paper and pencil to draw and create comics. It wasn’t until my 20’s I discovered photoshop and my eyes were opened to a world of possibilities. College showed me how much I can push my self with my art and I started to dabble in 3d rendering and animation as well as photomanipulation.</p>
          <div className="button__holder">
            <Link to={'/contact'} className="contact">
              Contact
            </Link>
            <Link to={'/gallery'} className="gallery">
              Gallery
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About
