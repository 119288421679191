import React from "react";


import { ImageGallery } from "react-image-grid-gallery";

const GalleryGrid = () => {
  const imagesArray = [
    {
      src: require("../../assets/images/red.jpg"),
    },
    {
      src: require("../../assets/images/ashoka.jpg"),
    },
    {
      src: require("../../assets/images/batman.jpg"),
    },
    {
      src: require("../../assets/images/blackcat.jpg"),
    },
    {
      src: require("../../assets/images/cavern.jpg"),
    },
    {
      src: require("../../assets/images/cyberCity.png"),
    },
    {
      src: require("../../assets/images/dareDevil.jpg"),
    },
    {
      src: require("../../assets/images/darthVader.jpg"),
    },
    {
      src: require("../../assets/images/posteffects4.png"),
    },
    {
      src: require("../../assets/images/donatello.jpg"),
    },
    {
      src: require("../../assets/images/fractalTiger.jpg"),
    },
    {
      src: require("../../assets/images/gamora.jpg"),
    },
    {
      src: require("../../assets/images/gonkAndMousedroids.jpg"),
    },
    {
      src: require("../../assets/images/grass.jpg"),
    },
    {
      src: require("../../assets/images/harleyAndJoker.jpg"),
    },
    {
      src: require("../../assets/images/kaang.jpg"),
    },
    {
      src: require("../../assets/images/hotelscene.png"),
    },
    {
      src: require("../../assets/images/leia.jpg"),
    },
    {
      src: require("../../assets/images/leonardo.jpg"),
    },
    {
      src: require("../../assets/images/michelangelo.jpg"),
    },
    {
      src: require("../../assets/images/obiwan.jpg"),
    },
    {
      src: require("../../assets/images/outdoorScene.jpg"),
    },
    {
      src: require("../../assets/images/rainyalley.png"),
    },
    {
      src: require("../../assets/images/hotelscene.png"),
    },
    {
      src: require("../../assets/images/shackLight.png"),
    },
    {
      src: require("../../assets/images/slaveLeia.jpg"),
    },
    {
      src: require("../../assets/images/rooftop.png"),
    },
    {
      src: require("../../assets/images/snowScene.jpg"),
    },
    {
      src: require("../../assets/images/spiderwomanAndBatgirl.jpg"),
    },

    {
      src: require("../../assets/images/tigerlilly.jpg"),
    },
    {
      src: require("../../assets/images/turtles.jpg"),
    },
    {
      src: require("../../assets/images/underground.png"),
    },
    {
      src: require("../../assets/images/batmanTwo.jpg"),
    },
  ];
  return (
    <div>
     
         <ImageGallery
        className="gallery__image"
        imagesInfoArray={imagesArray}
        columnCount={"auto"}
        columnWidth={200}
        gapSize={12}
      />
      
     
    </div>
  );
};

export default GalleryGrid;
