import React from 'react'
import './footer.scss'

import { FaInstagram, FaArtstation, FaDeviantart  } from "react-icons/fa";


const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer__icons">
          <a href="https://www.instagram.com/cdickson231/">
            <FaInstagram className='icon'/>
          </a>
          <a href="https://www.artstation.com/chrisdickson7">
            <FaArtstation className='icon'/>
          </a>
          <a href="https://www.deviantart.com/eggman231">
            <FaDeviantart className='icon' />
          </a>
        </div>
        
      </div>
    </div>
  )
}

export default Footer
